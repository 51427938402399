import { createTranslatorFactory as o, ITSELF as t } from "@ucast/core";
export * from "@ucast/core";
import { MongoQueryParser as e, allParsingInstructions as r, defaultParsers as c } from "@ucast/mongo";
export * from "@ucast/mongo";
import { createJsInterpreter as n, allInterpreters as f, compare as s } from "@ucast/js";
export * from "@ucast/js";
function i(o) {
  return null === o || "object" != typeof o ? o : o instanceof Date ? o.getTime() : o && "function" == typeof o.toJSON ? o.toJSON() : o;
}
const m = (o, t) => s(i(o), i(t));
function p(r, c, f) {
  const s = new e(r),
    i = n(c, Object.assign({
      compare: m
    }, f));
  if (f && f.forPrimitives) {
    const o = {
        field: t
      },
      e = s.parse;
    s.setParse(t => e(t, o));
  }
  return o(s.parse, i);
}
const u = p(r, f),
  a = p(["$and", "$or"].reduce((o, t) => (o[t] = Object.assign({}, o[t], {
    type: "field"
  }), o), Object.assign({}, r, {
    $nor: Object.assign({}, r.$nor, {
      type: "field",
      parse: c.compound
    })
  })), f, {
    forPrimitives: !0
  }),
  j = u;
export { p as createFactory, j as filter, u as guard, a as squire };
